import React, { useState, createContext, useMemo, useEffect } from "react";
import { ParallaxProvider } from "react-scroll-parallax";

export const wrapRootElement = ({ element }) => {
  return (
    <ContextProv>
      <ParallaxProvider>{element}</ParallaxProvider>
    </ContextProv>
  );
};
const ContextProv = ({ children }) => {
  const [active, setActive] = useState(false);
  const [load, setLoad] = useState(true);
  const activeData = useMemo(() => ({ active, setActive }), [active]);

  useEffect(() => {
    const handleInteraction = () => {
      setActive(true);
    };

    document.body.addEventListener("keydown", handleInteraction);
    document.body.addEventListener("click", handleInteraction);
    document.body.addEventListener("touchstart", handleInteraction);
  }, [children]);
  useEffect(() => {
    setLoad(false);
  }, []);
  return (
    <ActiveUserContext.Provider value={activeData}>
      {children}
    </ActiveUserContext.Provider>
  );
};

export const ActiveUserContext = createContext({
  active: [],
  setActive: () => {},
});
